import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectHeading = makeShortcode("ProjectHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProjectHeading title='Product designer with a passion for building equitable and purposeful systems' abstract='Currently a product designer at Microsoft' borderBottom mdxType="ProjectHeading" />
    <p>{`I've worked with organizations both large and small on identifying user needs throughout all phases of product development while practicing iterative design, testing, and delivery. I am happiest when I’m collaborating with diverse, cross functional teammates. I believe in using design to grapple with human complexities instead of paring them away.`}</p>
    <p>{`Are you interested in building something together? Send me `}<a parentName="p" {...{
        "href": "mailto:kristy.leung11@gmail.com"
      }}>{`a message`}</a>{`, check out `}<a parentName="p" {...{
        "href": "/Kristy-Leung-Product-Designer-Resume.pdf"
      }}>{`my resume`}</a>{` or keep up with me on `}<a parentName="p" {...{
        "href": "https://ca.linkedin.com/in/kristyleung"
      }}>{`LinkedIn`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/kristyleunggg"
      }}>{`Twitter`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      